var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mb-3",
      class: _vm.data.type,
      attrs: { id: "menu-" + _vm.data.type + "-type1" }
    },
    [
      _c("div", { staticClass: "sport__header d-flex align-center px-4" }, [
        _c(
          "div",
          { staticClass: "sport__header--img" },
          [
            _vm.data.type === "sport"
              ? _c("Icon", {
                  staticClass: "title--text",
                  attrs: {
                    data: require("@icon/block/game/type2/sport.svg"),
                    width: "18",
                    height: "18"
                  }
                })
              : _c("Icon", {
                  staticClass: "title--text",
                  attrs: {
                    data: require("@icon/block/game/type2/esport.svg"),
                    width: "18",
                    height: "18"
                  }
                })
          ],
          1
        ),
        _c(
          "span",
          {
            staticClass:
              "sport__header--title font-weight-bold ml-2 title--text"
          },
          [_vm._v(" " + _vm._s(_vm.data.data.navbar[0].game_type) + " ")]
        )
      ]),
      _c(
        "div",
        [
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "mySwiper px-4 mt-2",
              attrs: { options: _vm.swiperOption }
            },
            _vm._l(_vm.gameItem, function(game) {
              return _c(
                "swiper-slide",
                { key: game._id, staticClass: "game__item p-relative" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "game cursor-pointer rounded",
                      style: _vm.gradientBg,
                      on: {
                        click: function($event) {
                          return _vm.lauchGame(game.game_code)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "game__img rounded overflow-hidden",
                          class: game.status === "0" ? "maintenanceBox" : ""
                        },
                        [
                          game.status == "0" ? _c("maintenance") : _vm._e(),
                          _c("v-img", {
                            staticClass: "rounded",
                            attrs: {
                              src: _vm.baseImgUrl + game.image_h5_2,
                              height: "96"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "subtitle-2 white--text game__title p-absolute"
                        },
                        [_vm._v(" " + _vm._s(game.title) + " ")]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }